@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.cards {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 100%;
  }

  &__images {
    border-radius: var(--radius-main);
  }

  span {
    display: block;
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--color-gray-900);
  }

  .btn {
    margin-top: auto;
  }

  @include media(mobile-m) {
    .btn {
      max-width: 100%;
    }
  }
}
