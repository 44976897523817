@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.blog {
  $root: &;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    gap: 24px;

    h2 {
      margin-bottom: 0;
    }

    a {
      font-size: 20px;
      line-height: 1.6;
      letter-spacing: 0.01em;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.3s linear;

      &:hover {
        text-decoration-color: currentcolor;
      }
    }
  }

  .overlay {
    overflow: hidden;
    border-radius: var(--radius-big);

    &::before {
      background: var(--gradient-second);
    }

    a {
      &::before {
        content: "";
        position: absolute;
        inset: 0;
      }
    }

    &:hover {
      h3 {
        text-decoration-color: currentcolor;
      }
    }
  }

  &--main {
    #{$root}__content {
      flex-direction: column;
    }
  }

  h3 {
    position: absolute;
    top: 24px;
    padding: 0 20px;
    width: 100%;
    max-width: 338px;
    font-weight: var(--fw-600);
    font-size: clamp(20px, 1rem + 0.5556vi, 24px);
    line-height: 1.5;
    letter-spacing: 0.01em;
    color: var(--color-white);
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.3s linear;
  }

  @include media(tablet) {
    &__content {
      margin-bottom: 25px;
    }

    h3 {
      max-width: 100%;
    }
  }
}
