@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 16px 0;
  width: 100%;
  background-color: var(--color-brown-50);
  transition-property: background-color, transform;
  transition-duration: 0.25s;

  &.hide {
    transform: translateY(-200%);
  }

  @include media(desktop) {
    .logo {
      margin-right: 10px;
      max-width: 220px;
    }
  }

  @include media(tablet-wide) {
    .logo {
      max-width: 200px;
    }
  }
}
