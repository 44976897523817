@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --color-white: #fff;
  --color-black: #000;
  --color-dark-900: #252b42;
  --color-brown-10: #fff5ec;
  --color-brown-50: #f3e3d4;
  --color-brown-300: #9b6b43;
  --color-brown-400: #734c23;
  --color-brown-500: #442c13;
  --color-gray-300: #94959b;
  --radius-main: 12px;
  --radius-big: 24px;
  --gradient: linear-gradient(-90deg, #f3e3d400 1.319%, #f3e3d4e6 54.071%);
  --gradient-second: linear-gradient(360deg, #442c1300, #442c13 125.647%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-brown-300);
  background-color: var(--color-brown-50);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.4;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section {
  padding: 5.375rem 0;
}

@media only screen and (max-width: 992px) {
  .section {
    padding: 25px 0;
  }
}

.container, .wrapper {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.wrapper {
  max-width: 1340px;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.overlay {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.overlay:before {
  content: "";
  background: var(--gradient);
  position: absolute;
  inset: 0;
}

.logo {
  width: 100%;
  max-width: 290px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  display: flex;
}

.logo img {
  width: 100%;
}

.is-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.is-grid img {
  border-radius: var(--radius-main);
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .is-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .is-grid li:last-child {
    grid-column: 2 span;
  }

  .is-grid li:last-child img {
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 768px) {
  .is-grid {
    grid-template-columns: 1fr;
  }

  .is-grid li img {
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  .is-grid li:last-child {
    grid-column: auto;
  }
}

.gallery {
  padding: 8.75rem 0;
}

.gallery img {
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .gallery {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 992px) {
  .gallery {
    padding: 30px 0;
  }
}

.contacts {
  padding: 7rem 0;
}

.contacts h2 {
  text-align: center;
  margin-bottom: 3.875rem;
}

@media only screen and (max-width: 1180px) {
  .contacts {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 992px) {
  .contacts {
    padding: 30px 0;
  }

  .contacts h2 {
    margin-bottom: 30px;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-600);
  color: var(--color-brown-400);
  font-size: clamp(35px, 1.5rem + 1.6667vi, 48px);
  line-height: 1.2;
  overflow: hidden;
}

h2, h3 {
  font-weight: var(--fw-700);
  margin-bottom: 46px;
  font-size: clamp(25px, 1.3333rem + 1.0185vi, 36px);
}

@media only screen and (max-width: 992px) {
  h2 {
    margin-bottom: 24px;
  }
}

h3 {
  margin-bottom: 16px;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.title {
  font-size: clamp(18px, .875rem + 1.1111vi, 30px);
  line-height: 1.33;
}

p {
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-brown-50);
  padding: 16px 0;
  transition-property: background-color, transform;
  transition-duration: .25s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

@media only screen and (max-width: 1280px) {
  .header .logo {
    max-width: 220px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1180px) {
  .header .logo {
    max-width: 200px;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 33px;
}

.nav__link {
  background-color: #0000;
  font-size: 18px;
  line-height: 1.21;
  transition: color .3s linear;
}

.nav__link:hover {
  color: var(--color-brown-500);
}

.nav__link.true {
  font-weight: var(--fw-700);
}

.nav__btns {
  align-items: center;
  gap: 24px;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-child) {
    margin-right: 10px;
  }

  .nav__btns {
    gap: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.2);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-brown-50);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .25s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin: 0;
  }

  .nav__item {
    margin-bottom: 20px;
  }

  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .nav__link {
    font-size: 20px;
  }

  .nav__btns {
    flex-direction: column;
    gap: 24px;
  }
}

[class].btn {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-600);
  color: var(--color-white);
  background-color: var(--color-brown-300);
  border: 2px solid #0000;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 7px 18px;
  font-size: 18px;
  line-height: 1.21;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-brown-300);
  background-color: var(--color-white);
  color: var(--color-brown-300) !important;
}

[class].btn--invert {
  border-color: var(--color-brown-300);
  color: var(--color-brown-300);
  background-color: #0000;
}

[class].btn--invert:hover, [class].btn--invert:focus {
  background-color: var(--color-brown-300);
  color: var(--color-white) !important;
}

[class].btn--slider {
  z-index: 2;
  min-height: 64px;
  max-width: 64px;
  border-radius: 16px;
  padding: 15px;
}

[class].btn--slider:before {
  content: "";
  width: 26px;
  height: 17px;
  background-color: currentColor;
  transition: background-color .3s linear;
  position: relative;
  -webkit-mask-image: url("../images/icons/arrow.svg");
  mask-image: url("../images/icons/arrow.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

[class].btn--slider:hover {
  color: var(--color-brown-400);
}

[class].btn--arrow {
  font-weight: var(--fw-400);
  color: var(--color-brown-400);
  background-color: #0000;
  gap: 10px;
  margin-bottom: 16px;
  padding: 7px 20px;
}

[class].btn--arrow:before {
  content: "";
  width: 26px;
  height: 17px;
  background-color: currentColor;
  transition: background-color .3s linear;
  position: relative;
  scale: -1 1;
  -webkit-mask-image: url("../images/icons/arrow.svg");
  mask-image: url("../images/icons/arrow.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

[class].btn--arrow:hover {
  background-color: #0000;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-brown-400);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content:not(:last-child) {
  margin-bottom: 24px;
}

.policy h1 {
  text-align: center;
  margin: 0 auto 48px;
  font-size: clamp(25px, 1.3333rem + 1.0185vi, 36px);
}

.policy h2 {
  margin-bottom: 24px;
  font-size: clamp(18px, .875rem + 1.1111vi, 30px);
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  padding-left: 10px;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  color: var(--color-brown-400);
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-brown-300);
  text-decoration: underline;
  transition: color .3s linear;
}

.policy a:hover {
  color: var(--color-brown-400);
}

@media only screen and (max-width: 992px) {
  .policy h1 {
    margin-bottom: 30px;
  }
}

.footer__top {
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  padding: 5.375rem 0;
  display: flex;
}

.footer__mail {
  color: var(--color-black);
  flex-wrap: wrap;
  gap: 41px;
  margin-bottom: 19px;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .3s linear, text-decoration-color .3s linear;
  display: flex;
}

.footer__mail span {
  color: var(--color-brown-400);
}

.footer__mail:hover {
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
}

.footer__bottom {
  color: var(--color-white);
  background-color: var(--color-brown-400);
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  line-height: 1.21;
  display: flex;
}

.footer__copy {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__copy p {
  font-size: 16px;
}

.footer__policy {
  justify-content: space-between;
  gap: 53px;
  display: flex;
}

.footer__policy a {
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .3s linear, text-decoration-color .3s linear;
}

.footer__policy a:hover {
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
}

.footer .logo {
  max-width: 347px;
  margin: 0;
}

@media only screen and (max-width: 1180px) {
  .footer__top {
    padding: 50px 0;
  }

  .footer__mail {
    gap: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .footer__top {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer__top {
    flex-direction: column;
    place-items: center;
  }
}

@media only screen and (max-width: 576px) {
  .footer__copy {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
  }

  .footer__policy {
    justify-content: center;
    gap: 16px;
  }
}

.form__inner {
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 27px;
  display: grid;
}

.form label {
  width: 100%;
  max-width: 100%;
}

.form label span {
  font-weight: var(--fw-600);
  letter-spacing: .01em;
  gap: 2px;
  margin-bottom: 17px;
  font-size: 18px;
  display: flex;
  position: relative;
}

.form label span:after {
  content: "";
  width: 8px;
  height: 8px;
  background-image: url("../images/icons/label.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 6px;
}

.form label:last-of-type {
  grid-column: 2 span;
}

.form input, .form textarea {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: 100%;
  color: var(--color-gray-300);
  background-color: var(--color-white);
  padding: 20px 16px;
  overflow: hidden;
}

.form input::placeholder, .form textarea::placeholder {
  letter-spacing: .01em;
  font-size: 18px;
  line-height: 1.21;
}

.form textarea {
  min-height: 162px;
  resize: none;
  border: none;
  padding: 10px 16px;
}

.form .btn {
  max-width: 100%;
  background-color: var(--color-brown-400);
  padding: 20px;
}

.form--footer {
  width: 100%;
  max-width: 495px;
  gap: 8px;
  display: flex;
}

.form--footer .btn {
  max-width: 136px;
  align-self: flex-end;
  padding: 8px 19px;
}

.form--footer label span {
  font-weight: var(--fw-500);
  letter-spacing: 0;
  margin-bottom: 9px;
  font-size: 20px;
}

.form--footer label span:after {
  content: none;
}

.form--footer label input {
  border: 1px solid var(--color-gray-300);
  padding: 9px 8px;
}

@media only screen and (max-width: 992px) {
  .form__inner {
    gap: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .form input {
    padding: 16px;
  }

  .form textarea {
    min-height: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .form__inner {
    grid-template-columns: 1fr;
  }

  .form label:last-of-type {
    grid-column: auto;
  }
}

@media only screen and (max-width: 375px) {
  .form--footer {
    flex-direction: column;
  }

  .form--footer .btn {
    max-width: 100%;
  }
}

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
}

.hero__inner {
  z-index: 1;
  min-height: 646px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.hero__content {
  width: 100%;
  max-width: 594px;
  padding: 16px 0;
}

.hero h1 {
  margin-bottom: 24px;
}

.hero p {
  margin-bottom: 48px;
}

.hero .btn {
  padding: 15px 18px;
}

.hero--post .hero__content {
  max-width: 471px;
}

.hero--post h2 {
  margin-bottom: 0;
}

.hero--post .btn {
  padding: 9px 20px;
}

@media only screen and (max-width: 992px) {
  .hero:before {
    background: #f3e3d4cc;
  }

  .hero__inner {
    min-height: 500px;
  }

  .hero__content {
    max-width: 100%;
  }

  .hero p {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .hero .hero__inner {
    min-height: 350px;
  }

  .hero .hero__inner p {
    font-size: 18px;
  }

  .hero .btn:not(.btn--arrow) {
    max-width: 100%;
  }
}

.additional {
  padding-bottom: 120px;
}

.additional__inner {
  position: relative;
}

.additional__images img {
  border-radius: var(--radius-big);
  overflow: hidden;
}

.additional__content {
  border-radius: var(--radius-big);
  width: 100%;
  max-width: 484px;
  background-color: var(--color-brown-10);
  padding: 48px;
  position: absolute;
  top: 12%;
  right: 0;
  overflow: hidden;
}

.additional__content p {
  margin-bottom: 31px;
}

.additional--suits {
  padding-bottom: 190px;
}

.additional--suits .additional__content p {
  margin-bottom: 0;
}

.additional--guide {
  padding-bottom: 146px;
}

.additional h2 {
  margin-bottom: 32px;
}

.additional .btn {
  padding: 16px 20px;
}

@media only screen and (max-width: 1180px) {
  .additional {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 992px) {
  .additional {
    padding-bottom: 30px;
  }

  .additional__content {
    padding: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .additional__inner {
    flex-direction: column-reverse;
    gap: 30px;
    display: flex;
  }

  .additional__content {
    max-width: 100%;
    position: relative;
    inset: 0;
  }
}

.faq__item {
  border: 2px solid var(--color-brown-400);
  border-radius: var(--radius-main);
  letter-spacing: .02em;
  overflow: hidden;
}

.faq__item:not(:last-child) {
  margin-bottom: 29px;
}

.faq details[open] summary {
  padding-bottom: 16px;
}

.faq details[open] summary:after {
  transform: scaleY(1);
}

.faq summary {
  font-weight: var(--fw-600);
  color: var(--color-brown-400);
  cursor: pointer;
  align-items: center;
  padding: 24px 32px;
  font-size: clamp(1.125rem, 1.0833rem + .1852vi, 1.25rem);
  line-height: 1.2;
  display: flex;
  position: relative;
}

.faq summary:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/chevron.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform .3s linear;
  position: absolute;
  top: 24px;
  right: 24px;
  transform: scaleY(-1);
}

.faq summary + div {
  padding: 0 24px 24px 32px;
}

.faq summary + div p {
  font-size: 18px;
  line-height: 1.2;
}

.faq h2 {
  text-align: center;
  margin: 0 auto 45px;
}

@media only screen and (max-width: 992px) {
  .faq {
    padding-bottom: 40px;
  }

  .faq__item:before {
    bottom: -14px;
  }

  .faq__item:not(:last-child) {
    margin-bottom: 16px;
  }

  .faq__item summary {
    padding: 14px 28px 14px 14px;
  }

  .faq__item summary:after {
    top: 7px;
    right: 0;
  }

  .faq__item summary + div {
    padding: 0 14px 14px;
  }

  .faq__item h2, .faq h2 {
    margin-bottom: 30px;
  }
}

.reviews {
  width: 100%;
  max-width: 1128px;
  margin-inline: auto;
  padding: 7.0625rem 0;
}

.reviews__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  display: grid;
}

.reviews li {
  border-radius: var(--radius-big);
  height: 100%;
  background-color: var(--color-brown-10);
  flex-direction: column;
  padding: 2.5rem;
  display: flex;
  overflow: hidden;
}

.reviews img:first-of-type {
  border-radius: 50%;
  margin-bottom: 2.5rem;
  overflow: hidden;
}

.reviews img:last-of-type {
  margin-bottom: 32px;
}

.reviews p {
  letter-spacing: .01em;
  margin-bottom: 3.125rem;
  line-height: 1.21;
}

.reviews h2 {
  text-align: center;
  margin-bottom: 3.875rem;
}

.reviews h3 {
  margin: auto 0 0;
  font-size: clamp(18px, .875rem + 1.1111vi, 30px);
}

@media only screen and (max-width: 1180px) {
  .reviews {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 992px) {
  .reviews {
    padding: 30px 0;
  }

  .reviews__list {
    gap: 24px;
  }

  .reviews li {
    padding: 16px;
  }

  .reviews h2 {
    margin-bottom: 30px;
  }

  .reviews p, .reviews img:first-of-type {
    margin-bottom: 25px;
  }

  .reviews img:last-of-type {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .reviews__list {
    grid-template-columns: 1fr;
  }
}

.cards {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.cards__content {
  height: 100%;
  flex-direction: column;
  padding: 16px;
  display: flex;
}

.cards__images {
  border-radius: var(--radius-main);
}

.cards span {
  color: var(--color-gray-900);
  margin-bottom: 16px;
  font-size: 16px;
  display: block;
}

.cards .btn {
  margin-top: auto;
}

@media only screen and (max-width: 576px) {
  .cards .btn {
    max-width: 100%;
  }
}

.featured h3 {
  margin-bottom: 12px;
}

.featured p {
  margin-bottom: 32px;
  font-size: 18px;
}

.featured--suits p {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .featured p {
    margin-bottom: 16px;
  }
}

.blog__content {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 50px;
  display: flex;
}

.blog__content h2 {
  margin-bottom: 0;
}

.blog__content a {
  letter-spacing: .01em;
  font-size: 20px;
  line-height: 1.6;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .3s linear, text-decoration-color .3s linear;
}

.blog__content a:hover {
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
}

.blog .overlay {
  border-radius: var(--radius-big);
  overflow: hidden;
}

.blog .overlay:before {
  background: var(--gradient-second);
}

.blog .overlay a:before {
  content: "";
  position: absolute;
  inset: 0;
}

.blog .overlay:hover h3 {
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
}

.blog--main .blog__content {
  flex-direction: column;
}

.blog h3 {
  width: 100%;
  max-width: 338px;
  font-weight: var(--fw-600);
  letter-spacing: .01em;
  color: var(--color-white);
  padding: 0 20px;
  font-size: clamp(20px, 1rem + .5556vi, 24px);
  line-height: 1.5;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .3s linear, text-decoration-color .3s linear;
  position: absolute;
  top: 24px;
}

@media only screen and (max-width: 992px) {
  .blog__content {
    margin-bottom: 25px;
  }

  .blog h3 {
    max-width: 100%;
  }
}

.slider {
  padding: 7rem 0 7.875rem;
}

.slider h2 {
  text-align: center;
  margin: 0 auto 4rem;
}

.slider__swiper {
  overflow: hidden;
}

.slider__list {
  margin-bottom: 35px;
}

.slider__slide {
  width: 100%;
  max-width: 1128px;
  opacity: .2;
  flex-direction: column;
  place-items: center;
  margin: 0 auto;
  transition: opacity .4s ease-in-out, scale 1.5s ease-in-out;
  display: flex;
  scale: .56;
}

.slider__slide.swiper-slide-active {
  opacity: 1;
  margin: 0 auto;
  scale: 1;
}

.slider__slide.swiper-slide-active .slider__content {
  opacity: 1;
}

.slider__slide.swiper-slide-next {
  translate: -22% -4%;
}

.slider__slide.swiper-slide-prev {
  translate: 22% -4%;
}

.slider__content {
  width: 100%;
  max-width: 845px;
  text-align: center;
  opacity: 0;
  margin: 0 auto;
}

.slider--events {
  padding: 7rem 0 6.0625rem;
}

.slider--events .slider__list {
  margin-bottom: 4rem;
}

.slider__nav {
  --swiper-pagination-color: var(--color-brown-400);
  --swiper-pagination-bullet-size: 16px;
  width: 335px;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  display: flex;
  position: relative;
}

.slider__nav .btn.swiper-button-disabled {
  opacity: .2;
  pointer-events: none;
}

.slider__nav .btn--prev {
  left: 0;
  scale: -1 1;
}

.slider__nav .btn--next {
  left: auto;
  right: 0;
}

.slider__nav .swiper-pagination {
  z-index: 1;
  position: relative;
  bottom: 0;
}

.slider__nav .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
}

.slider img {
  border-radius: var(--radius-big);
  min-height: 250px;
  margin-bottom: 4rem;
  overflow: hidden;
  box-shadow: 0 8px 8px -4px #1118270a, 0 20px 24px -4px #1118271a;
}

.slider p {
  font-weight: var(--fw-500);
  font-size: clamp(18px, 1rem + .5556vi, 24px);
  line-height: 1.2;
}

.slider h3 {
  margin-bottom: 21px;
}

@media only screen and (max-width: 1180px) {
  .slider {
    padding: 60px 0;
  }

  .slider img, .slider h2 {
    margin-bottom: 30px;
  }

  .slider--events {
    padding: 60px 0;
  }

  .slider--events .slider__list {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .slider {
    padding: 30px 0;
  }
}

.post {
  letter-spacing: .02em;
  color: var(--color-brown-500);
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.post__descr:not(:last-child) {
  margin-bottom: 48px;
}

.post__descr p:not(:last-child) {
  margin-bottom: 44px;
}

.post__descr p, .post__descr span {
  line-height: 1;
}

.post__descr span {
  font-weight: var(--fw-700);
}

.post__descr ul {
  margin-bottom: 46px;
}

.post__descr ul li {
  line-height: 1;
}

.post__descr ul li:not(:first-child) {
  padding-left: 30px;
  position: relative;
}

.post__descr ul li:not(:first-child):before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 11px;
}

.post__descr ul li:first-child {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .post__descr ul {
    padding-left: 15px;
  }
}

.post__content {
  margin-bottom: 48px;
  line-height: 1.21;
}

.post h2 {
  color: var(--color-brown-500);
  margin-bottom: 24px;
  font-size: clamp(18px, .875rem + 1.1111vi, 30px);
  line-height: 1.33;
}

.post img {
  border-radius: var(--radius-big);
  margin-bottom: 48px;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .post__content, .post__descr:not(:last-child), .post__descr ul {
    margin-bottom: 24px;
  }

  .post__descr ul li:not(:first-child) {
    padding-left: 15px;
  }

  .post__descr ul li:not(:first-child):before {
    left: 0;
  }

  .post__descr p:not(:last-child), .post img {
    margin-bottom: 24px;
  }

  .post h2 {
    margin-bottom: 16px;
  }
}

.rental {
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 8.6875rem 0 8.75rem;
  display: grid;
}

.rental__content {
  width: 100%;
  max-width: 379px;
}

.rental img {
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .rental {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 992px) {
  .rental {
    grid-template-columns: 1fr;
    padding: 30px 0;
  }

  .rental__content {
    max-width: 100%;
  }
}

.choose {
  letter-spacing: .02em;
  color: var(--color-brown-400);
  padding-top: 0;
}

.choose__list li:not(:last-child) {
  margin-bottom: 48px;
}

.choose__list p {
  line-height: 1.2;
}

.choose__list img {
  min-height: 150px;
}

.choose img {
  border-radius: var(--radius-big);
  overflow: hidden;
}

.choose .title {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .choose li:not(:last-child) {
    margin-bottom: 24px;
  }
}

/*# sourceMappingURL=main.css.map */
