@use "../utils/functions/" as *;
@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.featured {
  h3 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 32px;
    font-size: 18px;
  }

  &--suits {
    p {
      margin-bottom: 0;
    }
  }

  @include media(tablet) {
    p {
      margin-bottom: 16px;
    }
  }
}
