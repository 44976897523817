@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.slider {
  $root: &;

  padding: rem(112) 0 rem(126);

  h2 {
    margin: 0 auto rem(64);
    text-align: center;
  }

  &__swiper {
    overflow: hidden;
  }

  &__list {
    margin-bottom: 35px;
  }

  &__slide {
    width: 100%;
    max-width: 1128px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin: 0 auto;
    transition:
      opacity 0.4s ease-in-out,
      scale 1.5s ease-in-out;
    opacity: 0.2;
    scale: 0.56;

    &.swiper-slide-active {
      opacity: 1;
      scale: 1;
      margin: 0 auto;

      .slider__content {
        opacity: 1;
      }
    }

    &.swiper-slide-next {
      translate: -22% -4%;
    }

    &.swiper-slide-prev {
      translate: 22% -4%;
    }
  }

  &__content {
    margin: 0 auto;
    width: 100%;
    max-width: 845px;
    text-align: center;
    opacity: 0;
  }

  &--events {
    padding: rem(112) 0 rem(97);

    #{$root}__list {
      margin-bottom: rem(64);
    }
  }

  &__nav {
    --swiper-pagination-color: var(--color-brown-400);
    --swiper-pagination-bullet-size: 16px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 335px;
    margin-inline: auto;

    .btn {
      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &--prev {
        left: 0;
        scale: -1 1;
      }

      &--next {
        left: auto;
        right: 0;
      }
    }

    .swiper-pagination {
      position: relative;
      bottom: 0;
      z-index: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
    }
  }

  img {
    margin-bottom: rem(64);
    overflow: hidden;
    border-radius: var(--radius-big);
    min-height: 250px;
    box-shadow:
      0 8px 8px -4px rgba(17, 24, 39, 0.04),
      0 20px 24px -4px rgba(17, 24, 39, 0.1);
  }

  p {
    font-weight: var(--fw-500);
    font-size: clamp(18px, 1rem + 0.5556vi, 24px);
    line-height: 1.2;
  }

  h3 {
    margin-bottom: 21px;
  }

  @include media(tablet-wide) {
    padding: 60px 0;

    img {
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 30px;
    }

    &--events {
      padding: 60px 0;

      #{$root}__list {
        margin-bottom: 30px;
      }
    }
  }

  @include media(tablet) {
    padding: 30px 0;
  }
}
