@mixin bg-cover {
  content: "";
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin bg-contain {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin mask {
  content: "";
  position: relative;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: cover;
  background-color: currentcolor;
  transition: background-color 0.3s linear;
}
