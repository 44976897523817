@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 33px;
    }
  }

  &__link {
    font-size: 18px;
    line-height: 1.21;
    background-color: transparent;
    transition: color 0.3s linear;

    &:hover {
      color: var(--color-brown-500);
    }

    &.true {
      font-weight: var(--fw-700);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  @include media(tablet-wide) {
    &__item {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &__btns {
      gap: 10px;
    }
  }

  @include media(tablet) {
    &__inner {
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.2);
      width: 100%;
      height: var(--vh);
      max-width: 350px;
      background-color: var(--color-brown-50);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.25s;
    }

    &__list {
      z-index: -1;
      flex-direction: column;
      margin: 0;
    }

    &__item {
      margin-bottom: 20px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &__link {
      font-size: 20px;
    }

    &__btns {
      flex-direction: column;
      gap: 24px;
    }
  }
}
