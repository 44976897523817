@use "../../utils/functions/" as *;
@use "../../utils/mixins" as *;

.choose {
  padding-top: 0;
  letter-spacing: 0.02em;
  color: var(--color-brown-400);

  &__list {
    li {
      &:not(:last-child) {
        margin-bottom: 48px;
      }
    }

    p {
      line-height: 1.2;
    }

    img {
      min-height: 150px;
    }
  }

  img {
    overflow: hidden;
    border-radius: var(--radius-big);
  }

  .title {
    margin-bottom: 24px;
  }

  @include media(tablet) {
    li {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
