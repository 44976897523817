/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;

  //
  --color-white: #fff;
  --color-black: #000;
  --color-dark-900: #252b42;
  --color-brown-10: #fff5ec;
  --color-brown-50: #f3e3d4;
  --color-brown-300: #9b6b43;
  --color-brown-400: #734c23;
  --color-brown-500: #442c13;
  --color-gray-300: #94959b;
  //  radius
  --radius-main: 12px;
  --radius-big: 24px;

  // gradients
  --gradient: linear-gradient(-90deg, rgba(243, 227, 212, 0) 1.319%, rgba(243, 227, 212, 0.9) 54.071%);
  --gradient-second: linear-gradient(360deg, rgba(68, 44, 19, 0), rgb(68, 44, 19) 125.647%);
}
