@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2,
h3 {
  overflow: hidden;
  font-weight: var(--fw-600);
  font-size: clamp(35px, 1.5rem + 1.6667vi, 48px);
  line-height: 1.2;
  color: var(--color-brown-400);
}

h2,
h3 {
  margin-bottom: 46px;
  font-weight: var(--fw-700);
  font-size: clamp(25px, 1.3333rem + 1.0185vi, 36px);
}

h2 {
  @include media(tablet) {
    margin-bottom: 24px;
  }
}

h3 {
  margin-bottom: 16px;
  font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);
}

.title {
  font-size: clamp(18px, 0.875rem + 1.1111vi, 30px);
  line-height: 1.33;
}

p {
  font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);
}
