@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.faq {
  &__item {
    overflow: hidden;
    border: 2px solid var(--color-brown-400);
    border-radius: var(--radius-main);
    letter-spacing: 0.02em;

    &:not(:last-child) {
      margin-bottom: 29px;
    }
  }

  details {
    &[open] {
      summary {
        padding-bottom: 16px;

        &::after {
          transform: scaleY(1);
        }
      }
    }
  }

  summary {
    position: relative;
    display: flex;
    align-items: center;
    padding: 24px 32px;
    font-weight: var(--fw-600);
    font-size: clamp(1.125rem, 1.0833rem + 0.1852vi, 1.25rem);
    line-height: 1.2;
    color: var(--color-brown-400);
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      right: 24px;
      top: 24px;
      width: 32px;
      height: 32px;
      background-image: url("../images/icons/chevron.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: scaleY(-1);
      transition: transform 0.3s linear;
    }

    + div {
      padding: 0 24px 24px 32px;

      p {
        font-size: 18px;
        line-height: 1.2;
      }
    }
  }

  h2 {
    margin: 0 auto 45px;
    text-align: center;
  }

  @include media(tablet) {
    padding-bottom: 40px;

    &__item {
      &::before {
        bottom: -14px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      summary {
        padding: 14px 28px 14px 14px;

        &::after {
          right: 0;
          top: 7px;
        }

        + div {
          padding: 0 14px 14px;
        }
      }

      h2 {
        margin-bottom: 30px;
      }
    }

    h2 {
      margin-bottom: 30px;
    }
  }
}
