@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  $root: &;

  border-radius: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 646px;
  }

  &__content {
    width: 100%;
    max-width: 594px;
    padding: 16px 0;
  }

  h1 {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 48px;
  }

  .btn {
    padding: 15px 18px;
  }

  &--post {
    #{$root}__content {
      max-width: 471px;
    }

    h2 {
      margin-bottom: 0;
    }

    .btn {
      padding: 9px 20px;
    }
  }

  @include media(tablet) {

    &::before {
      background:  rgba(243, 227, 212, 0.8);
    }

    &__inner {
      min-height: 500px;
    }

    &__content {
      max-width: 100%;
    }

    p {
      margin-bottom: 24px;
    }
  }



  @include media(mobile-m) {
    #{$root}__inner {
      min-height: 350px;

      p {
        font-size: 18px;
      }
    }

    .btn {
      &:not(.btn--arrow) {
        max-width: 100%;
      }
    }
  }
}
